import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BatchScenariosResponse } from 'prosumer-app/stores/batch';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Scenario } from '../../models';
import {
  DeleteScenariosComponent,
  DeleteScenariosData,
} from '../delete-scenarios';
import { ScenarioBatchAction } from '../scenario-batch';

type BatchProcessorFn = (scenarios: Scenario[]) => void;

@Injectable()
export class ScenarioListService {
  private readonly deleteSuccess = new Subject<boolean>();
  deleteSuccess$ = this.deleteSuccess.asObservable();

  constructor(
    private readonly dialog: MatDialog,
    private readonly snacky: NotificationsService,
    private readonly scenarioFacade: ScenarioFacadeService,
  ) {}

  batchScenarios(action: ScenarioBatchAction, scenarios: Scenario[]): void {
    const processorFn = this.getBatchProcessor(action);
    processorFn(scenarios);
  }

  selectScenarioFacadeList(): Observable<Scenario[]> {
    return this.scenarioFacade.dataList$;
  }

  private getBatchProcessor(action: string): BatchProcessorFn {
    const processorMap = {
      [ScenarioBatchAction.download]: this.downloadScenarios.bind(this),
      [ScenarioBatchAction.delete]: this.deleteScenarios.bind(this),
    };
    return processorMap[action];
  }

  private downloadScenarios(scenarios: Scenario[]): void {
    scenarios.forEach((scenario) => {
      this.scenarioFacade.download(scenario, 'getOutputSigned', 'output');
    });
  }

  private deleteScenarios(scenarios: Scenario[]): void {
    this.dialog
      .open(DeleteScenariosComponent, {
        data: this.buildDeleteScenariosData(scenarios),
        width: '40vw',
      })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe((success: BatchScenariosResponse) =>
        this.conditionallyHandleDeleteCompletion(success),
      );
  }

  private conditionallyHandleDeleteCompletion(
    response: BatchScenariosResponse,
  ): void {
    const { totalItems, totalSkipped } = response;
    const success = this.isSuccess(totalItems, totalSkipped);
    this.deleteSuccess.next(success);
    if (success) {
      this.snacky.showSuccess(this.getSuccessMsg(response));
    } else {
      this.snacky.showError(this.getErrorMsg());
    }
  }

  private isSuccess(totalItems: number, totalSkipped: number): boolean {
    return [totalItems > 0, totalSkipped < totalItems].every(Boolean);
  }

  private getSuccessMsg(response: BatchScenariosResponse): string {
    const { totalItems, totalSkipped } = response;
    return `${
      totalItems - totalSkipped
    } of the ${totalItems} selected scenarios have been successfully deleted.`;
  }

  private getErrorMsg(): string {
    return 'Something went wrong while deleting the selected scenarios.';
  }

  private buildDeleteScenariosData(scenarios: Scenario[]): DeleteScenariosData {
    return { scenarios };
  }
}
